export const API_SUBURL = {
  BANNER: '/winfieldunited/services/banner/v1',
  MY_LINKS: '/winfieldunited/services/mylinks-integration/v1',
  SETTINGS: '/enterprise/platform/settings/v1',
  SEARCH: '/winfieldunited/services/search/v1',
};

export const QUERYKEYS = {
  DELETE_BANNER_MESSAGE: 'delete-banner-message',
  GET_BANNER_MESSAGES: 'get-banner-messages',
  GET_BANNER_MESSAGE_DETAILS: 'get-banner-message',
  GET_MY_LINKS: 'get-my-links',
  GET_SETTINGS_UI_MENU: 'get-settings-ui-menu',
  GET_SUGGESTED_OPTIONS: 'get-suggested-options',
};

export enum SECURITY_ROLES {
  ANSWER_TECH_RESTRICTED_EMPLOYEE = 'GG_AnswerTech_Restricted_Employee',
  ANSWER_TECH_RESTRICTED_RETAILER = 'GG_AnswerTech_Restricted_Retailer',
  MARKETING_APP_ADMIN = 'GG_WU_Portal_Marketing_Admin',
  LOYALTY_ADMIN = 'GG_WU_Services_Loyalty_Admin',
  LOYALTY_ANALYTICS = 'GG_WU_Services_Analytics_Catalyst_Seller',
  LOYALTY_SELLER = 'GG_WU_Services_Loyalty_Seller',
  LOANS_SECURE_SELLER = 'GG_WU_Services_Loans_Secure_Seller',
  LOANS_SECURE_MANAGER = 'GG_WU_Services_Loans_Secure_Manager',
}

export const ALERT_INPUT_FIELDS = [
  { label: 'name' },
  { label: 'message' },
  { label: 'url' },
  { label: 'link text' },
  {
    label: 'start date',
    type: 'date',
    required: true,
  },
  { label: 'end date', type: 'date', required: true },
  {
    label: 'audience',
    type: 'select',
    values: ['Portal and SMA', 'Portal', 'SMA'],
  },
];

export const INITIAL_ALERT_FORM_DATA = {
  name: '',
  message: '',
  url: '',
  linkText: '',
  startDate: '',
  minDate: new Date().toISOString().split('T')[0],
  endDate: '',
  audience: 'Portal and SMA' as 'Portal and SMA' | 'Portal' | 'SMA',
};

export const INITIAL_ALERT_VALIDATION_STATE = {
  name: '',
  message: '',
  url: '',
  linkText: '',
  startDate: '',
  endDate: '',
};

export const MY_LINKS_CODES = {
  SERVICES_AND_PROGRAMS: [
    'agrimine2',
    'canadaEvolve',
    'fgiportal',
    'SECURE',
    'seedReporting',
    'SureTech',
    'TIE',
    'TIEC',
    'wuCashlessFarming',
    'wfEvolve',
    'wfEvolveAdmin',
  ] as string[],
  PRODUCTS_AND_GUIDES: ['GrowerRewards', 'ToolsSeedguide'] as string[],
  DUPLICATES: ['agrimine2', 'seedReporting', 'wfEvolve', 'wfEvolveAdmin'], //see story 312631 for reference
  USEFUL_LINKS: ['agrimine2', 'seedReporting', 'wfEvolve', 'btinvoicemgmt'],
};
