import React from 'react';

import { useAccount } from '@azure/msal-react';

import { CoreTypography, Grid, styled } from '@atlas-ui/core';

const StyledGrid = styled(Grid)({
  marginRight: '20px',
  '& .user-account__name': {
    color: '#FFFFFF',
    fontFamily: 'acumin-pro',
    fontSize: '0.9375rem',
  },
}) as typeof Grid;

const UserAccountMobile = (): React.ReactElement => {
  const account = useAccount();

  return (
    <StyledGrid
      container
      width="fit-content"
      alignItems="center"
      role="menu"
      data-testid="user-account-component"
      className="user-account-component">
      <CoreTypography className="user-account__name" component="div">
        Greetings, {account?.idTokenClaims?.name}
      </CoreTypography>
    </StyledGrid>
  );
};

export default UserAccountMobile;
