import React, {
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import debounce from 'lodash.debounce';
import { useLocation } from 'react-router-dom';

import { Box, styled, useMediaQuery, useTheme } from '@atlas-ui/core';
import { COLORS } from '@wu-ui/common';

import { useAppContext } from '../../contexts/AppContext';
import { APP_ROUTE_MAIN_PATHS } from '../../utils/appRoutes';
import useIs404Page from '../../utils/hooks/useIs404';

interface IAdditionalStyledProps {
  fullBleed: boolean;
  showPortalNavigation: boolean;
  minHeight?: string;
  staticHeight?: string;
  showNavigation?: boolean;
}

const StyledAppLayout = styled(Box)<IAdditionalStyledProps>(
  ({
    theme,
    fullBleed,
    minHeight,
    staticHeight = 'auto',
    showNavigation,
    showPortalNavigation,
  }) => ({
    maxWidth: fullBleed ? '1728px' : '1672px',
    margin: '0 auto',
    padding: fullBleed ? '0 40px' : '0 130px',
    height: staticHeight,
    minHeight: minHeight, // needed for fixing Footer on bottom, 190px footer, 80px header, 4px needed not getting scroll on biggest resolutions
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',

      '& .__navigation-container-static:not(#portal-navigation)': {
        display: showPortalNavigation ? 'flex' : 'none',
      },
    },

    '& .__navigation-container-static:not(#portal-navigation)': !showNavigation
      ? {
          backgroundColor: COLORS.WHITE,
          boxShadow: `calc(-0.5*100vw) 0 0 0 ${COLORS.WHITE}, calc(0.5*100vw) 0 0 0 ${COLORS.WHITE}`,
          [theme.breakpoints.down('sm')]: {
            backgroundColor: '#2F2F2F',
            boxShadow:
              'calc(-0.5*100vw) 0 0 0 #2F2F2F, calc(0.5*100vw) 0 0 0 #2F2F2F',
          },
        }
      : {},
  }),
);

const AppLayout = ({
  showPortalNavigation,
  children,
}: PropsWithChildren<{ showPortalNavigation: boolean }>): JSX.Element => {
  const { pathname } = useLocation();
  const { navigation } = useAppContext();

  const [fullBleed, setFullBleed] = useState(false);
  const [staticHeight, setStaticHeight] = useState<string>();
  const [minHeight, setMinHeight] = useState<string>();

  const is404Page = useIs404Page();

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const conditionalComputations = (
    footerHeight: number,
    headerHeight: number,
  ) => {
    if (is404Page) {
      setStaticHeight(undefined);
      return;
    }

    if (pathname === APP_ROUTE_MAIN_PATHS.CHAT_AI) {
      /**
       * Temporary push the footer off the screen to allow more estate for the Chat-AI
       * Original calculation:
       * `calc(100vh - ${footerHeight}px - ${headerHeight}px - 4px)`,
       */
      setStaticHeight(`calc(100vh - ${headerHeight}px - 4px)`);
    } else {
      setStaticHeight(undefined);
    }
  };

  const handleScreenResize = () => {
    const footerHeight =
      document.getElementById('wu-app_footer-container')?.clientHeight || 0;
    const headerHeight =
      document.getElementById('wu-app_header-mobile')?.clientHeight ||
      document.getElementById('wu-app_header-container')?.clientHeight ||
      0;

    /**
     * Temporary exclude the footer from the calculations
     * Original calculation:
     * setMinHeight(`calc(100vh - ${footerHeight}px - ${headerHeight}px - 4px)`);
     */
    setMinHeight(`calc(100vh - ${headerHeight}px - 4px)`);

    conditionalComputations(footerHeight, headerHeight);
  };

  useLayoutEffect(() => {
    const debouncedScreenResize = debounce(handleScreenResize, 100);

    // Invoke calculation explicitly on mount to compute the correct heights
    debouncedScreenResize();

    window.addEventListener('resize', debouncedScreenResize);

    return () => window.removeEventListener('resize', debouncedScreenResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (
      pathname.startsWith(APP_ROUTE_MAIN_PATHS.CATALYST) ||
      pathname.startsWith(APP_ROUTE_MAIN_PATHS.LOANS) ||
      pathname.startsWith(APP_ROUTE_MAIN_PATHS.PROGRAM_ENROLLMENT)
    ) {
      setFullBleed(false);
    } else {
      setFullBleed(true);
    }
  }, [pathname]);

  useEffect(() => {
    handleScreenResize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  /**
   * Hide footer on Chat-AI page for mobile
   */
  useEffect(() => {
    if (pathname === APP_ROUTE_MAIN_PATHS.CHAT_AI && isMobile) {
      document
        .getElementById('wu-app_footer-container')
        ?.style.setProperty('display', 'none');
    } else {
      document
        .getElementById('wu-app_footer-container')
        ?.style.setProperty('display', 'flex');
    }
  }, [pathname, isMobile]);

  return (
    <StyledAppLayout
      id="app-layout"
      fullBleed={fullBleed}
      minHeight={minHeight}
      staticHeight={staticHeight}
      showNavigation={navigation.show}
      showPortalNavigation={showPortalNavigation}>
      {children}
    </StyledAppLayout>
  );
};

export default AppLayout;
