import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const reactPlugin = new ReactPlugin();

const isLocalDev =
  process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.APP_INSIGHTS_CONN_STRING,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    disableInstrumentationKeyValidation: isLocalDev,
    disableTelemetry: isLocalDev,
  },
});

appInsights.loadAppInsights();

export { appInsights, reactPlugin };
