import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import { APP_ROUTE_MAIN_PATHS } from '../utils/appRoutes';

interface IPublicRoutesConfig {
  [key: string]: {
    path: string;
    exact: boolean;
  };
}

export const publicRoutesConfig: IPublicRoutesConfig = {
  health: {
    path: APP_ROUTE_MAIN_PATHS.HEALTH_CHECK,
    exact: true,
  },
};

const PublicRoutes = () => {
  return (
    <Switch>
      <Route
        {...publicRoutesConfig.health}
        render={(): JSX.Element => <Fragment />}
      />
    </Switch>
  );
};

export default PublicRoutes;
