export const APP_ROUTE_MAIN_PATHS = {
  ADMIN: '/admin',
  CATALYST: '/catalyst',
  CHAT_AI: '/chat-ai',
  LOANS: '/secure',
  PROGRAM_ENROLLMENT: '/program-enrollment',
  SEARCH: '/search',
  SETTINGS: '/settings',
  USER_PROFILE: '/user-profile',
  // PUBLIC routes
  HEALTH_CHECK: '/health/live',
};

export const APP_ROUTE_SUBPATHS = {
  // ADMIN/*
  ADMIN_MANAGE_ALERTS: '/manage-alerts',
};
