import { useCallback, useState } from 'react';

function useForceRerender() {
  const [, setTick] = useState(0);

  const forceRender = useCallback(() => {
    setTick(Math.random());
  }, []);

  return forceRender;
}

export default useForceRerender;
