const getPortalUiMenu = {
  auditInfo: {
    createdAt: '0001-01-01T00:00:00+00:00',
    modifiedAt: '0001-01-01T00:00:00+00:00',
  },
  settings: {
    menu: [
      {
        code: 'growerOpportunities',
        label: 'Grower Opportunities',
        url: 'https://portalstage.winfieldunited.com/grower-opportunities',
        icon: 'fa-grower-opportunities',
        openNewWindow: true,
        claims: null,
      },
      {
        code: 'acreReporting',
        label: 'Acre Reporting',
        url: 'https://portalstage.winfieldunited.com/PowerBI',
        icon: 'fa-chart',
        openNewWindow: true,
        claims: null,
      },
      {
        code: 'applications',
        label: 'Applications',
        url: 'https://portalstage.winfieldunited.com/Products-and-Services',
        icon: 'fa-monitor',
        openNewWindow: true,
        claims: null,
      },
      {
        code: 'documents',
        label: 'Documents',
        url: 'https://portalstage.winfieldunited.com/documents',
        icon: 'fa-document',
        openNewWindow: true,
        claims: null,
      },
      {
        code: 'events',
        label: 'Events',
        url: 'https://portalstage.winfieldunited.com/events',
        icon: 'fa-calendar',
        openNewWindow: true,
        claims: null,
      },
      {
        code: 'yourDocuments',
        label: 'Your Documents',
        url: 'https://portalstage.winfieldunited.com/Public-Documents',
        icon: 'fa-profile-doc',
        openNewWindow: true,
        claims: null,
      },
      {
        code: 'catalyst',
        label: 'Catalyst',
        url: '/catalyst',
        icon: 'fa-catalyst',
        openNewWindow: false,
        claims: [
          'GG_WU_Services_Analytics_Catalyst_Seller',
          'GG_WU_Services_Loyalty_Seller',
        ],
      },
    ],
    headerNavigation: [
      {
        code: 'growerOpportunities',
        label: 'Opportunities',
        url: 'https://portalstage.winfieldunited.com/grower-opportunities',
        icon: null,
        openNewWindow: true,
        claims: null,
      },
      {
        code: 'productsAndServices',
        label: 'Products and Services',
        url: 'https://portalstage.winfieldunited.com/Products-and-Services',
        icon: null,
        openNewWindow: true,
        claims: null,
      },
      {
        code: 'applications',
        label: 'Applications',
        url: 'https://portalstage.winfieldunited.com/applications',
        icon: null,
        openNewWindow: true,
        claims: null,
      },
      {
        code: 'documents',
        label: 'Documents',
        url: 'https://portalstage.winfieldunited.com/documents',
        icon: null,
        openNewWindow: true,
        claims: null,
      },
      {
        code: 'events',
        label: 'Events',
        url: 'https://portalstage.winfieldunited.com/events',
        icon: null,
        openNewWindow: true,
        claims: null,
      },
      {
        code: 'admin',
        label: 'Admin',
        url: '/admin',
        icon: null,
        openNewWindow: false,
        claims: ['GG_WU_Portal_Marketing_Admin'],
      },
    ],
  },
};

export default getPortalUiMenu;
