import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
  QueryCache,
  QueryClient as QueryClientV5,
  QueryClientProvider as QueryClientProviderV5,
} from '@tanstack/react-query';
import {
  QueryClient as QueryClientV3,
  QueryClientProvider as QueryClientProviderV3,
} from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { ThemeProvider, useMediaQuery, WuTheme } from '@atlas-ui/core';
import { COLORS, Toaster, useErrorHandler } from '@wu-ui/common';

import AppHeader from './components/AppHeader/AppHeader';
import AppLayout from './components/AppLayout/AppLayout';
import Footer from './components/Footer/Footer';
import PortalNavigation from './components/PortalNavigation/PortalNavigation';
import AppContextProvider from './contexts/AppContext';
import RouteManager from './routes/RouteManager';

import { reactPlugin } from './utils/appInsights';

interface IApp {
  instance: PublicClientApplication;
}

const App = ({ instance }: IApp) => {
  const [showPortalNavigation, setShowPortalNavigation] = useState(true);
  const { onError } = useErrorHandler();

  const isSmallScreen = useMediaQuery(WuTheme.breakpoints.down('sm'));

  const queryClientV3 = useMemo(
    () =>
      new QueryClientV3({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
          },
        },
      }),
    [],
  );

  const queryClientV5 = useMemo(
    () =>
      new QueryClientV5({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
          },
        },
        queryCache: new QueryCache({
          onError: (error: any, { meta }) => {
            if (meta?.disableGlobalErrorHandling) {
              return;
            }
            return onError(error);
          },
        }),
      }),
    [onError],
  );

  useEffect(() => {
    if (isSmallScreen) {
      setShowPortalNavigation(false);
    } else {
      setShowPortalNavigation(true);
    }
  }, [isSmallScreen]);

  useEffect(() => {
    if (document.getElementById('adobe-analytics')) return;

    const adobeScript = document.createElement('script');
    adobeScript.id = 'adobe-analytics';
    adobeScript.src = process.env.ADOBE_ANALYTICS_SCRIPT_URL ?? '';
    adobeScript.async = true;

    document.body.appendChild(adobeScript);
  }, []);

  useLayoutEffect(() => {
    document.body.style.backgroundColor = COLORS.BACKGROUND;
  });

  return (
    <MsalProvider instance={instance}>
      <QueryClientProviderV5 client={queryClientV5}>
        <QueryClientProviderV3 client={queryClientV3}>
          <BrowserRouter>
            <AppContextProvider>
              <ThemeProvider theme={WuTheme}>
                <button
                  id="invisible-logout"
                  style={{ display: 'none' }}
                  onClick={() => instance.logoutRedirect()}>
                  logout
                </button>
                <AppHeader
                  showPortalNavigation={showPortalNavigation}
                  setShowPortalNavigation={setShowPortalNavigation}
                />
                <AppLayout showPortalNavigation={showPortalNavigation}>
                  {showPortalNavigation && <PortalNavigation />}
                  <RouteManager />
                </AppLayout>
                <Footer />
                <Toaster />
              </ThemeProvider>
            </AppContextProvider>
          </BrowserRouter>
        </QueryClientProviderV3>
      </QueryClientProviderV5>
    </MsalProvider>
  );
};

export default withAITracking(reactPlugin, App);
