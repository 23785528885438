const updateAlertResponse = {
  $type: 'message',
  messageId: 'r-2CDonqFBCvpPtUpg8aS',
  messageType: 'banner',
  location: 'topCenter',
  isDismissible: false,
  effective: {
    $type: 'effectiveperiod',
    from: '2024-01-09T00:00:00+00:00',
    to: '2024-10-09T00:00:00+00:00',
  },
  audiences: ['portal'],
  content: {
    $type: 'linkedcontent',
    contentUrl: 'https://some-url.com',
    linkText: 'Link to some url',
    title: 'balaala',
    body: '123',
  },
  auditInfo: {
    $type: 'auditinfo',
    createdAt: '2023-11-09T12:11:31+00:00',
    createdById: 'e2e_auto-banner-creator@portal.wu.com',
    modifiedAt: '2023-11-09T16:41:47+00:00',
    modifiedById: 'banner_admin_test',
  },
};

export default updateAlertResponse;
