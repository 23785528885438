import React, { PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AuthenticatedTemplate } from '@azure/msal-react';

import {
  Box,
  CoreButton,
  Grid,
  styled,
  useMediaQuery,
  useTheme,
} from '@atlas-ui/core';
import { COLORS, INavItem, INavTile, TabsNavigation } from '@wu-ui/common';

import { useAppContext } from '../../contexts/AppContext';
import {
  APP_ROUTE_MAIN_PATHS,
  APP_ROUTE_SUBPATHS,
} from '../../utils/appRoutes';
import { MY_LINKS_CODES } from '../../utils/constants';
import useForceRerender from '../../utils/hooks/useForceRerender';
import useIs404Page from '../../utils/hooks/useIs404';
import DashboardIcon from '../DashboardIcon/DashboardIcon';

const StyledPlaceholder = styled(Box)({
  height: '68px',
});

interface IAdditionalStyledProps {
  isDashboard: boolean;
}

const StyledContainer = styled(Grid)<IAdditionalStyledProps>(
  ({ isDashboard, theme }) => ({
    position: 'relative',
    boxShadow: `calc(-0.5*100vw) 0 0 0 ${COLORS.WHITE}, calc(0.5*100vw) 0 0 0 ${COLORS.WHITE}`,
    background: COLORS.WHITE,

    '& #portal-navigation': {
      width: isDashboard ? 'calc(100% - 130px)' : '100%',
    },

    [theme.breakpoints.down('sm')]: {
      boxShadow:
        'calc(-0.5*100vw) 0 0 0 #2f2f2f, calc(0.5*100vw) 0 0 0 #2f2f2f',
      background: '#2f2f2f',
      '& #portal-navigation': {
        width: '100%',
      },
    },
  }),
);

const StyledShareAnAssetButton = styled(CoreButton)(({ theme }) => ({
  position: 'absolute',
  right: '0',
  top: '10px',
  zIndex: 101,
  backgroundColor: '#4C721D',
  height: '47px',
  border: 'none',
  borderRadius: '4px',
  color: COLORS.WHITE,
  padding: '15px',
  fontFamily: 'inherit',
  fontWeight: 'normal',
  fontSize: '0.8125rem',
  '&:hover': {
    backgroundColor: '#4C721D',
    border: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    top: '0',
    margin: '20px 0',
  },
}));

const AppendToAppLayout = ({ children }: PropsWithChildren) => {
  const mainNavigationPlaceholder = document.querySelector(
    '.main-navigation-placeholder',
  );

  if (mainNavigationPlaceholder) {
    return createPortal(children, mainNavigationPlaceholder);
  }
  return children;
};

const PortalNavigation = () => {
  const { wuSettings, myLinks, hasUserReqPermissions, navigation } =
    useAppContext();
  const { pathname } = useLocation();
  const forceRerender = useForceRerender();
  const observerRef = useRef<MutationObserver | null>(null);
  const is404Page = useIs404Page();

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const tabsNavigationIconWrapperElement = document.querySelector(
    '.__navigation-container-static:not(#portal-navigation) .__navigation-container-static-icon-wrapper',
  );

  const appendIconToNavigation = (
    <>
      {tabsNavigationIconWrapperElement &&
        createPortal(<DashboardIcon />, tabsNavigationIconWrapperElement)}
    </>
  );

  useEffect(() => {
    const showMainNav =
      pathname.startsWith(APP_ROUTE_MAIN_PATHS.CATALYST) ||
      pathname.startsWith(APP_ROUTE_MAIN_PATHS.LOANS);

    navigation.set(!showMainNav);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    let mainNavigationPlaceholder = document.querySelector(
      '.main-navigation-placeholder',
    );

    if (!mainNavigationPlaceholder) {
      mainNavigationPlaceholder = document.createElement('div');
      mainNavigationPlaceholder.classList.add('main-navigation-placeholder');
    }

    const observerCallback = () => {
      const microNavigation = document.querySelector(
        '.__navigation-container-static:not(#portal-navigation)',
      );

      if (microNavigation) {
        const sibling = microNavigation.nextSibling; // microfrontend content
        microNavigation.parentElement?.insertBefore(
          mainNavigationPlaceholder as Element,
          sibling,
        );
        forceRerender();
        observerRef.current?.disconnect();
      }
    };

    if (!observerRef.current) {
      observerRef.current = new MutationObserver(observerCallback);
    }

    observerRef?.current?.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observerRef?.current?.disconnect();
    };
  }, [pathname, forceRerender]);

  const navigationItems = useMemo(() => {
    const navItems = (wuSettings?.headerNavigation ?? []).reduce(
      (acc, navItem) => {
        const containers = navItem.containers?.map(container => {
          //Filters only the hardcoded tiles coming from our settings service
          const tiles = container?.tiles.filter(
            tile => !tile?.claims || hasUserReqPermissions(tile?.claims),
          );
          return { ...container, tiles };
        });

        return !navItem?.claims || hasUserReqPermissions(navItem?.claims)
          ? [
              ...acc,
              {
                code: navItem.code as string,
                to: navItem.url as string,
                name: navItem.label as string,
                dataTestId: `${navItem.code}-nav`,
                icon: navItem.icon,
                containers: JSON.parse(JSON.stringify(containers ?? null)),
              },
            ]
          : acc;
      },
      [] as INavItem[],
    );

    const mappedLinks =
      (myLinks?.map(({ title, iconUrl, description, code, url }) => ({
        title,
        description,
        iconURL: iconUrl,
        code: code?.toLowerCase(),
        url,
      })) as INavTile[]) ?? [];

    const productsAndGuides =
      navItems.find(({ code }) => code === 'productsAndGuides')?.containers?.[0]
        .tiles ?? [];
    const servicesAndPrograms =
      navItems.find(({ code }) => code === 'servicesAndPrograms')
        ?.containers?.[0].tiles ?? [];
    const tools =
      navItems.find(({ code }) => code === 'tools')?.containers?.[0].tiles ??
      [];

    mappedLinks.forEach(link => {
      if (
        MY_LINKS_CODES.DUPLICATES.map(code => code.toLowerCase()).includes(
          link.code,
        )
      ) {
        servicesAndPrograms.push(link);
        tools.push(link);
      } else if (
        MY_LINKS_CODES.SERVICES_AND_PROGRAMS.map(code =>
          code.toLowerCase(),
        ).includes(link.code)
      ) {
        servicesAndPrograms.push(link);
      } else if (
        MY_LINKS_CODES.PRODUCTS_AND_GUIDES.map(code =>
          code.toLowerCase(),
        ).includes(link.code)
      ) {
        productsAndGuides.push(link);
      } else {
        tools.push(link);
      }
    });

    navItems
      .filter(({ containers }) => containers && containers.length > 0)
      .forEach(({ containers }) => {
        const tiles = containers?.[0]?.tiles;
        if (tiles) {
          tiles.sort((a: INavTile, b: INavTile) =>
            a.title.localeCompare(b.title),
          );
        }
      });

    return [
      {
        code: 'dashboard',
        to: '/',
        name: 'dashboard',
        dataTestId: 'dashboard-nav',
        containers: null,
        exact: true,
      },
      ...navItems,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wuSettings?.headerNavigation, myLinks]);

  const isDashboard = pathname === '/';

  if (
    (isDashboard ||
      is404Page ||
      pathname.startsWith(
        APP_ROUTE_MAIN_PATHS.ADMIN + APP_ROUTE_SUBPATHS.ADMIN_MANAGE_ALERTS,
      ) ||
      pathname.startsWith(APP_ROUTE_MAIN_PATHS.SEARCH) ||
      pathname.startsWith(APP_ROUTE_MAIN_PATHS.CATALYST) ||
      pathname.startsWith(APP_ROUTE_MAIN_PATHS.LOANS) ||
      pathname.startsWith(APP_ROUTE_MAIN_PATHS.CHAT_AI)) &&
    navigation.show
  )
    return (
      <AuthenticatedTemplate>
        <CSSTransition
          in={Boolean(wuSettings.headerNavigation)} // Set the in prop to true to trigger the transition
          appear={false} // Set the appear prop to true to animate on initial mount
          timeout={800} // Set the duration of the transition in milliseconds
          classNames="slide-in-top" // Set the class name for the transition
        >
          <>
            {appendIconToNavigation}
            {wuSettings.headerNavigation ? (
              <AppendToAppLayout>
                <StyledContainer
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  isDashboard={isDashboard}>
                  <TabsNavigation
                    navItems={navigationItems}
                    id="portal-navigation"
                    enableOverlay={
                      pathname.startsWith(APP_ROUTE_MAIN_PATHS.CHAT_AI) &&
                      !isMobile
                    }
                  />
                  {isDashboard && !isMobile && (
                    <StyledShareAnAssetButton
                      onClick={() =>
                        window.open(process.env.KENTICO_SHARE_ASSET_LINK ?? '')
                      }
                      data-testid="share-an-asset-button">
                      SHARE AN ASSET
                    </StyledShareAnAssetButton>
                  )}
                </StyledContainer>
              </AppendToAppLayout>
            ) : (
              <StyledPlaceholder />
            )}
          </>
        </CSSTransition>
      </AuthenticatedTemplate>
    );

  return <>{appendIconToNavigation}</>;
};

export default PortalNavigation;
