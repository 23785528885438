const getBannerMessagesCurrent = {
  count: 7,
  cursors: {
    next: '/messages?Status=past&PageSize=5&Count=withCount&cursor=NXwxNjJDQjc4OQ',
    self: '/messages?Status=past&PageSize=5&Count=withCount',
  },
  items: [
    {
      $type: 'message',
      messageId: 'EXMl2r7p0G1TP5kUg0257',
      messageType: 'banner',
      location: 'topCenter',
      isDismissible: true,
      effective: {
        $type: 'effectiveperiod',
        from: '2022-01-01T03:00:00+03:00',
        to: '2022-02-01T03:00:00+03:00',
      },
      audiences: [],
      content: {
        $type: 'linkedcontent',
        contentUrl: 'https://mywebiste.com/external-content',
        linkText: 'Learn more',
        title: 'Future message 1',
        summary: 'This is the message content which is very descriptive.',
      },
      auditInfo: {
        $type: 'auditinfo',
        createdAt: '2023-10-30T18:12:47+00:00',
        createdById: 'banner_admin_test',
        modifiedAt: '2023-10-30T18:12:47+00:00',
        modifiedById: 'banner_admin_test',
      },
    },
    {
      $type: 'message',
      messageId: '3OmOijPFa711r_HrZk0yp',
      messageType: 'banner',
      location: 'topCenter',
      isDismissible: true,
      effective: {
        $type: 'effectiveperiod',
        from: '2022-02-01T03:00:00+03:00',
        to: '2022-03-01T03:00:00+03:00',
      },
      audiences: [],
      content: {
        $type: 'linkedcontent',
        contentUrl: 'https://mywebiste.com/external-content',
        linkText: 'Learn more',
        title: 'Future message 2',
        summary: 'This is the message content which is very descriptive.',
      },
      auditInfo: {
        $type: 'auditinfo',
        createdAt: '2023-10-30T18:16:08+00:00',
        createdById: 'banner_admin_test',
        modifiedAt: '2023-10-30T18:16:08+00:00',
        modifiedById: 'banner_admin_test',
      },
    },
    {
      $type: 'message',
      messageId: 'OrGDXpXcBw_orZlpwhq3x',
      messageType: 'banner',
      location: 'topCenter',
      isDismissible: true,
      effective: {
        $type: 'effectiveperiod',
        from: '2022-03-01T03:00:00+03:00',
        to: '2022-04-01T03:00:00+03:00',
      },
      audiences: [],
      content: {
        $type: 'linkedcontent',
        contentUrl: 'https://mywebiste.com/external-content',
        linkText: 'Learn more',
        title: 'Future message 3',
        summary: 'This is the message content which is very descriptive.',
      },
      auditInfo: {
        $type: 'auditinfo',
        createdAt: '2023-10-30T18:16:19+00:00',
        createdById: 'banner_admin_test',
        modifiedAt: '2023-10-30T18:16:19+00:00',
        modifiedById: 'banner_admin_test',
      },
    },
    {
      $type: 'message',
      messageId: 'I0kx_bkh-LyUIIzNzdET5',
      messageType: 'banner',
      location: 'topCenter',
      isDismissible: true,
      effective: {
        $type: 'effectiveperiod',
        from: '2022-04-01T03:00:00+03:00',
        to: '2022-05-01T03:00:00+03:00',
      },
      audiences: [],
      content: {
        $type: 'linkedcontent',
        contentUrl: 'https://mywebiste.com/external-content',
        linkText: 'Learn more',
        title: 'Future message 4',
        summary: 'This is the message content which is very descriptive.',
      },
      auditInfo: {
        $type: 'auditinfo',
        createdAt: '2023-10-30T18:16:31+00:00',
        createdById: 'banner_admin_test',
        modifiedAt: '2023-10-30T18:16:31+00:00',
        modifiedById: 'banner_admin_test',
      },
    },
    {
      $type: 'message',
      messageId: 'BR6zU4KQ3aZ3GMURPT5ex',
      messageType: 'banner',
      location: 'topCenter',
      isDismissible: true,
      effective: {
        $type: 'effectiveperiod',
        from: '2022-05-01T03:00:00+03:00',
        to: '2022-06-01T03:00:00+03:00',
      },
      audiences: [],
      content: {
        $type: 'linkedcontent',
        contentUrl: 'https://mywebiste.com/external-content',
        linkText: 'Learn more',
        title: 'Future message 5',
        summary: 'This is the message content which is very descriptive.',
      },
      auditInfo: {
        $type: 'auditinfo',
        createdAt: '2023-10-30T18:16:40+00:00',
        createdById: 'banner_admin_test',
        modifiedAt: '2023-10-30T18:16:40+00:00',
        modifiedById: 'banner_admin_test',
      },
    },
  ],
};

export default getBannerMessagesCurrent;
