import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAccount } from '@azure/msal-react';

import {
  CoreTypography,
  Grid,
  Icon,
  styled,
  useMediaQuery,
  useTheme,
} from '@atlas-ui/core';

import UserAccountMobile from './UserAccountMobile';

import { APP_ROUTE_MAIN_PATHS } from '../../utils/appRoutes';

const StyledGrid = styled(Grid)({
  backgroundColor: '#144E65',
  height: '80px',
  width: '308px',
  position: 'relative',
  cursor: 'pointer',
  '& .user-account__avatar-icon': {
    color: '#FAF9F8',
    fontSize: '2.8125rem',
    marginLeft: '13.5px',
    overflow: 'visible',
  },
  '& .user-account__name': {
    color: '#FFFFFF',
    fontFamily: 'acumin-pro',
    fontSize: '0.9375rem',
    marginLeft: '26px',
  },
}) as typeof Grid;

const UserAccount = (): React.ReactElement => {
  const account = useAccount();
  const history = useHistory();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const redirect = () => {
    history.push(APP_ROUTE_MAIN_PATHS.USER_PROFILE);
  };

  return isMobile ? (
    <UserAccountMobile />
  ) : (
    <StyledGrid
      container
      onClick={redirect}
      alignItems="center"
      role="menu"
      data-testid="user-account-component"
      className="user-account-component">
      <Icon className="user-account__avatar-icon fa fa-profile" />
      <CoreTypography className="user-account__name">
        {account?.idTokenClaims?.name}
      </CoreTypography>
    </StyledGrid>
  );
};

export default UserAccount;
