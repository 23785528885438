const createAlertResponse = {
  messageId: 'string',
  messageType: 'banner',
  location: 'topCenter',
  isDismissible: true,
  effective: {
    from: '2023-11-01T13:10:14.579Z',
    to: '2023-11-01T13:10:14.579Z',
  },
  audiences: ['portal'],
  content: {
    title: 'test title',
    summary: 'test summary',
  },
  auditInfo: {
    createdAt: '2023-11-01T13:10:14.579Z',
    createdById: 'string',
    modifiedAt: '2023-11-01T13:10:14.579Z',
    modifiedById: 'string',
  },
};

export default createAlertResponse;
