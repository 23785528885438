const getBannerMessagesNext = {
  $type: 'pagedresult',
  cursors: {
    $type: 'cursors',
    self: '/messages?Status=past&PageSize=5&Count=withCount&Cursor=NXwxNjJDQjc4OQ',
    previous:
      '/messages?Status=past&PageSize=5&Count=withCount&Cursor=MHwxNjJDQjc4RQ',
  },
  items: [
    {
      $type: 'message',
      messageId: 'NqhZO7RW3FB0mixGQeVZv',
      messageType: 'banner',
      location: 'topCenter',
      isDismissible: true,
      effective: {
        $type: 'effectiveperiod',
        from: '2022-06-01T03:00:00+03:00',
        to: '2022-07-01T03:00:00+03:00',
      },
      audiences: [],
      content: {
        $type: 'linkedcontent',
        contentUrl: 'https://mywebiste.com/external-content',
        linkText: 'Learn more',
        title: 'Future message 6',
        summary: 'This is the message content which is very descriptive.',
      },
      auditInfo: {
        $type: 'auditinfo',
        createdAt: '2023-10-30T18:16:49+00:00',
        createdById: 'banner_admin_test',
        modifiedAt: '2023-10-30T18:16:49+00:00',
        modifiedById: 'banner_admin_test',
      },
    },
    {
      $type: 'message',
      messageId: 'LyaKNLOtwHmSoK2ELWoQQ',
      messageType: 'banner',
      location: 'topCenter',
      isDismissible: true,
      effective: {
        $type: 'effectiveperiod',
        from: '2022-07-01T03:00:00+03:00',
        to: '2022-08-01T03:00:00+03:00',
      },
      audiences: [],
      content: {
        $type: 'linkedcontent',
        contentUrl: 'https://mywebiste.com/external-content',
        linkText: 'Learn more',
        title: 'Future message 7',
        summary: 'This is the message content which is very descriptive.',
      },
      auditInfo: {
        $type: 'auditinfo',
        createdAt: '2023-10-30T18:16:56+00:00',
        createdById: 'banner_admin_test',
        modifiedAt: '2023-10-30T18:16:56+00:00',
        modifiedById: 'banner_admin_test',
      },
    },
  ],
  count: 2,
};

export default getBannerMessagesNext;
