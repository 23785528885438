import React, { StrictMode } from 'react';
import { MockedRequest } from 'msw';
import { createRoot } from 'react-dom/client';

import {
  AuthenticationResult,
  EventType,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';

import App from './App';
import { msalConfig } from './authConfig';

import { worker } from '../test/mocks/browser';

import { appInsights } from './utils/appInsights';

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

const removeMsalEntriesFromLocalStorage = () => {
  const msalPatterns = ['msal.'].concat(...localStorage['msal.account.keys']);

  // Remove MSAL.js related entries from localStorage
  Object.keys(localStorage).forEach(key => {
    if (msalPatterns.some(pattern => key.startsWith(pattern))) {
      localStorage.removeItem(key);
    }
  });
};

msalInstance.addEventCallback(event => {
  const authenticationResult = event.payload as AuthenticationResult;

  appInsights.trackEvent({
    name: 'msal:authenticationResult',
    properties: {
      eventType: event?.eventType,
      interactionType: event?.interactionType,
      eventError: event?.error,
      userClaims: authenticationResult?.account?.idTokenClaims?.security_roles,
      context: authenticationResult?.account?.idTokenClaims?.context,
      userEmail: authenticationResult?.account?.idTokenClaims?.email,
    },
  });

  /**
   * Manually remove the msal and b2c entries from the local storage
   * otherwise post login in case of previous 400(EventType.ACQUIRE_TOKEN_FAILURE)
   * the old token is persistent in the store and resend for verification
   * This happens only for WinField users - when domain-hint is "winfieldunited".
   */
  if (
    (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE &&
      // @ts-expect-error types from msal are not considering monitor_window_timeout error
      event.error?.errorCode === 'monitor_window_timeout') ||
    event.error instanceof InteractionRequiredAuthError
  ) {
    removeMsalEntriesFromLocalStorage();

    // fallback to interaction when silent call fails
    return msalInstance.acquireTokenRedirect({
      scopes: ['openid', 'profile', 'offline_access'],
      domainHint: process.env.DOMAIN_HINT,
    });
  }

  if (authenticationResult === null) return;

  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    authenticationResult.account
  ) {
    msalInstance.setActiveAccount(authenticationResult.account);

    const { idTokenClaims } = authenticationResult.account;

    adobeDataLayer.push({
      event: 'login',
      user: {
        userId: idTokenClaims?.ag2ag,
        userType: idTokenClaims?.user_type,
      },
    });
  }
});

if (
  process.env.NODE_ENV === 'development' &&
  process.env.USE_MOCK_SERVER === 'true'
) {
  worker.start({
    onUnhandledRequest(req: MockedRequest) {
      // display warning message only for api requests
      if (req.url.pathname.startsWith('\\api')) {
        console.warn(
          'Found an unhandled %s request to %s',
          req.method,
          req.url.href,
        );
      }
    },
  });
}

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <App instance={msalInstance} />
  </StrictMode>,
);
