// this is put into here so I can share these same handlers between my tests
// as well as my development in the browser. Pretty sweet!
import { rest } from 'msw';

import { API_SUBURL } from '../../src/utils/constants';
import createAlertResponse from '../data/createAlertResponse';
import getBannerMessagesCurrent from '../data/getBannerMessagesCurrent';
import getBannerMessagesNext from '../data/getBannerMessagesNext';
import getPortalUiMenu from '../data/getPortalUiMenu';
import getSearchSuggestions from '../data/getSearchSuggestions';
import updateAlertResponse from '../data/updateAlertResponse';

const handlers = [
  rest.get(
    `${process.env.API_BASE_URL}${API_SUBURL.SETTINGS}/wu-portal-ui-menu`,
    (req, res, ctx) => {
      return res(ctx.status(200, 'Mock: Success'), ctx.json(getPortalUiMenu));
    },
  ),
  rest.get(
    `${process.env.API_BASE_URL}${API_SUBURL.SEARCH}/search/autocomplete`,
    (req, res, ctx) => {
      return res(
        ctx.status(200, 'Mock: Success'),
        ctx.json(getSearchSuggestions),
      );
    },
  ),
  rest.get(
    `${process.env.API_BASE_URL}${API_SUBURL.BANNER}/messages`,
    (req, res, ctx) => {
      const params = new URLSearchParams(req.url.search);
      const cursor = params.get('Cursor');

      return res(
        ctx.status(200, 'Mock: Success'),
        ctx.json(
          cursor === 'NXwxNjJDQjc4OQ'
            ? getBannerMessagesNext
            : getBannerMessagesCurrent,
        ),
      );
    },
  ),
  rest.post(
    process.env.API_BASE_URL + API_SUBURL.BANNER + '/messages',
    (req, res, ctx) => {
      return res(
        ctx.status(201, 'Mock: Created'),
        ctx.json(createAlertResponse),
      );
    },
  ),
  rest.put(
    process.env.API_BASE_URL + API_SUBURL.BANNER + '/messages/message',
    (req, res, ctx) => {
      return res(
        ctx.status(200, 'Mock: updated'),
        ctx.json(updateAlertResponse),
      );
    },
  ),
];

export { handlers };
